import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentcontainer"
import PortfolioSubSiteContent from "../../components/portfoliosubsitecontent"

export default function Belly({ data }) {

  return (
    <div>
      <Layout siteTitle={data.content.frontmatter.title}>
      <SEO
          title="Babybauch Shooting – Natürliche Babyfotos in Köln"
          description="Natürliche und liebevolle Babybauchfotos entspannt bei euch zuhause. Babyfotografin Köln und Umgebung. Reserviert jetzt euren Termin. Viele Bilder, Preise und Infos."
        />
        <ContentContainer>
          <PortfolioSubSiteContent imagestop={data.imagestop} imagesbottom={data.imagesbottom} content={data.content} />
        </ContentContainer>
      </Layout>
    </div>
  )
}

/*
  (1) Markdown holen (frei gewählten alias "content" für diesen Teil der Abfrage)
  Diese Funktion holt sich mit GraphQL den Markdown wird gefiltert nach dem Slug-Tag in der Datei
  in "html" ist dann das geparste Markdown als HTML String zu finden
  Übergeben werden die Daten an die Seitenfunktion über "data".
  Der Inhalt von Data lässt sich dann über {data.xxx} verwenden.
  Der HTML-String lässt sich über dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} injezieren
  (2) Images holen (frei gewählten alias "images" für diesen Teil der Abfrage)
  gatsby-image kann nur Bildern arbeiten, die von Graphql kommen; also: hiermit abfragen
*/
export const pageQuery = graphql`
  query {
    content: markdownRemark(frontmatter: {slug: {eq: "/content/portfolio/belly"}}) {
      frontmatter {
        date
        slug
        title
      }
      html
      fileAbsolutePath
    }
    imagestop: allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "images/portfolio/belly/top"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          id
          childrenImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imagesbottom: allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "images/portfolio/belly/bottom"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          id
          childrenImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
`